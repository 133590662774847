import React from 'react';

type Props = {
    className: string,
    bg?: string,
    borderRadius?: string
}

function Skeleton(props: Props) {
    return (
        <div className={`${props.bg || 'bg-gray-600'} ${props.borderRadius || 'rounded-md'} animate-pulse ${props.className}`}></div>
    )
}

export default Skeleton;
