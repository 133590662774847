import { FolderIcon } from '@heroicons/react/24/outline';
import React from 'react';

function EmptyToolList() {
    return (
        <div className="w-full bg-gray-700 mx-auto max-w-2xl p-8 rounded-xl flex flex-col items-center justify-center text-center">
            <FolderIcon className="h-24 w-24 text-gray-400" />
            <h1 className="text-3xl text-gray-300 mt-8">There are no available tools</h1>
        </div>
    );
}

export default EmptyToolList;
