const environment = {
    production: false,
    api: {
        // uncomment to work locally
        // baseUrl: 'https://metaryanalytics.test/api/v1',
        // baseUrlWeb: 'https://metaryanalytics.test',
        // staging. comment to work locally
        baseUrl: 'https://core.metaryanalytics.tech/api/v1',
        baseUrlWeb: 'https://core.metaryanalytics.tech',
    }
};

export default environment;
