import { MetricCategory } from "../models/metric";

export default (category: MetricCategory, categories: MetricCategory[]): MetricCategory[] => {
    const lineage = [category];
    let current = category;
    while (current.metric_category_id !== null) {
        const parent = categories.find(c => c.id === current.metric_category_id);
        if (parent) {
            lineage.push(parent);
            current = parent;
        } else {
            break;
        }
    }
    return lineage.reverse();
}
