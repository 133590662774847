import React from "react";
import { Navigate } from "react-router";
import { User } from "../models/user";
import { store } from "../store/store";

type Props = {
    redirectPath?: string,
    children: any,
}
const ProtectedRoute = ({ redirectPath = '/login', children}: Props) => {
    const user = store.getState().profile.user;

    if (!user) {
      return <Navigate to={redirectPath} replace />;
    }

    return children;
};

export default ProtectedRoute;
