import React, { useState } from 'react';
import { MetricDetail } from '../../models/metric';
import millify from 'millify';
import {
    Chart as ChartJS,
    registerables,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(...registerables);

type Props = {
    metric: MetricDetail;
}

function MetricCharts({ metric }: Props) {
    const options = metric.query.map(item => item.option);
    const [selectedOption, setSelectedOption] = useState<string>(options[0]);
    const query = metric.query.find(item => item.option === selectedOption)!;
    const result = metric.results[selectedOption];
    if (result.length === 0) {
        return (
            <div className="aspect-video flex items-center justify-center border border-dashed border-gray-600 rounded-2xl mt-8 text-gray-400">
                <p>We could not find any information about this chart in our database. Please, try again later.</p>
            </div>
        );
    }

    const labels = result.map((item: any) => item[query.chart_label]);
    const datasets = query.datasets.map(item => ({
        label: item.label,
        data: result.map((result: any) => result[item.column]),
        backgroundColor: item.fill_color,
        borderColor: item.fill_color,
        borderWidth: 1,
        yAxisID: item.scale === query.datasets[0].scale ? 'y' : 'y1',
    }));


    const scales: any = {
        y: {
            type: query.datasets[0].scale as any || 'linear',
            grid: {
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                tickBorderDash: [5, 5],
            },
            ticks: {
                display: true,
                padding: 10,
                color: '#b2b9bf',
                callback: function (value: any, index: any, ticks: any) {
                    if (!isNaN(value)) {
                        return millify(value, { precision: 3 });
                    }

                    return value;
                },
            }
        },
    };

    if (query.datasets[1]) {
        scales.y1 = {
            type: query.datasets[1].scale as any,
            position: 'right',
            grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
            ticks: {
                display: true,
                padding: 10,
                color: '#b2b9bf',
                callback: function (value: any, index: any, ticks: any) {
                    if (!isNaN(value)) {
                        return millify(value, { precision: 3 });
                    }

                    return value;
                },
            },
            backdropPadding: {
                y: 20
            }
        };
    }

    return (<>
            <nav>
                <ul className="flex space-x-4">
                    {options.map(option => (
                        <li key={option}>
                            <button
                                type="button"
                                className={`px-4 py-1 text-sm border rounded-md border-gray-600 ${option === selectedOption ? 'text-gray-100 bg-gray-600' : 'text-gray-400 hover:bg-gray-600'}`}
                                onClick={() => setSelectedOption(option)}
                            >
                                {option}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="aspect-video flex items-center justify-center border border-dashed border-gray-600 rounded-2xl mt-8 text-gray-400">
                <Chart
                    type={metric.chart_type}
                    data={{
                        labels,
                        datasets,
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    color: '#b2b9bf',
                                },
                            },
                        },
                        interaction: {
                            intersect: false,
                            mode: 'index',
                            axis: 'x'
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                        scales: {
                            ...scales,
                            x: {
                                grid: {
                                    display: false,
                                    drawOnChartArea: false,
                                    drawTicks: false,
                                    tickBorderDash: [5, 5],
                                },
                                ticks: {
                                    display: true,
                                    color: '#b2b9bf',
                                    padding: 20,
                                }
                            },
                        }
                    }}
                />
            </div>
        </>
    );
}

export default MetricCharts;
