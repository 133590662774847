import React from 'react';
import { Tool } from '../../models/tool';
import Skeleton from '../Skeleton';

type Props = {
    tool?: Tool
}

function ToolListItem(props: Props) {
    return (props.tool ?
        <a
            href={ props.tool.link }
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            aria-label={ props.tool.title }
        >
            <div className="flex-shrink-0 aspect-video relative">
                <img className="absolute left-0 top-0 w-full h-full object-cover" src={ props.tool.photo_url } alt={ props.tool.title } />
            </div>
            <div className="flex-1 p-6 flex flex-col justify-between bg-gray-600">
                <div className="flex-1">
                    <h2 className="text-xl font-semibold text-gray-100">{ props.tool.title }</h2>
                    <p className="mt-3 text-base text-gray-200 line-clamp-3">{ props.tool.description }</p>
                </div>
                <div className="mt-6 flex items-center">
                    <span className="block w-full text-center px-4 py-2 rounded text-primary-50 bg-primary-800 hover:bg-primary-700 cursor-pointer">Use tool</span>
                </div>
            </div>
        </a>
        : <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="bg-gray-600 flex-shrink-0 aspect-video" />
            <div className="flex-1 p-6 flex flex-col justify-between bg-gray-600">
                <div className="flex-1">
                    <Skeleton bg="bg-gray-500" className="w-3/4 h-8" />
                    <Skeleton bg="bg-gray-500" className="w-full h-6 mt-2" />
                    <Skeleton bg="bg-gray-500" className="w-3/5 h-6 mt-2" />
                </div>
                <div className="mt-6 flex items-center">
                    <Skeleton bg="bg-gray-500" className="h-10 w-full" />
                </div>
            </div>
        </div>
    );
}

export default ToolListItem;
