import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MetricCategory } from '../../models/metric';
import {
    ChevronDownIcon,
    FolderIcon,
    FolderOpenIcon,
    ChartBarIcon,
    HeartIcon,
} from '@heroicons/react/24/outline'
import { useAppSelector } from '../../store/hooks';
import { selectLikedMetrics } from '../../store/slices/likedMetricsSlice';
import getLineage from '../../utils/getLineage';
import LikeButton from './LikeButton';

type Props = {
    category: MetricCategory;
    categories: MetricCategory[];
}


function MetricTreeCategory(props: Props) {
    const children = props.categories.filter(category => category.metric_category_id === props.category.id);
    const lineage = getLineage(props.category, props.categories);
    const lineagePath = lineage.map(c => c.slug);
    const likedMetrics = useAppSelector(selectLikedMetrics);
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(location.pathname.includes(lineagePath.join('/')));
    }, [location.pathname]);

    return (
        <li className={`${props.category.metric_category_id ? 'pl-2 ml-2' : 'px-2'} py-1`}>
            <button type="button" onClick={() => setIsOpen(!isOpen)} className={`flex justify-start items-center p-2 rounded-md w-full text-sm text-gray-100 font-medium hover:bg-gray-600`}>
                { isOpen ? <FolderOpenIcon className='w-3 h-3 text-gray-400' /> : <FolderIcon className='w-3 h-3 text-gray-400' /> }
                <span className='px-2'>{ props.category.name }</span>
                <ChevronDownIcon className={`ml-auto w-3 h-3 text-gray-400 transition ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
            </button>
            { isOpen && (
                <ul>
                    { children.map(category => (
                        <MetricTreeCategory key={ category.id } category={ category } categories={ props.categories } />
                    )) }
                    { props.category.metrics.map(metric => (
                        <li key={ metric.id } className='pl-2 ml-2 py-1'>
                            <Link
                                to={`/metrics/${[...lineagePath, metric.slug].join('/')}`}
                                className={`flex justify-between items-center p-2 rounded-md w-full text-sm text-gray-100 font-medium hover:bg-gray-600 ${('/metrics/' + [...lineagePath, metric.slug].join('/')) === location.pathname ? 'bg-gray-600' : ''}`}
                            >
                                <span className="flex items-center justify-start w-full">
                                    <ChartBarIcon className='w-3 h-3 text-gray-400' />
                                    <span className='px-2'>{ metric.name }</span>
                                </span>
                                <LikeButton size="h-3 w-3" metric={ metric } />
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
}

export default MetricTreeCategory;
