import { ChartBarIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link } from 'react-router-dom';
import LikeButton from '../components/metrics/LikeButton';
import { MetricCategory } from '../models/metric';
import { useAppSelector } from '../store/hooks';
import { selectLikedMetrics } from '../store/slices/likedMetricsSlice';
import { selectMetrics } from '../store/slices/metricsSlice';
import getLineage from '../utils/getLineage';

function DefaultMetricPage() {
    const likedMetricIds = useAppSelector(selectLikedMetrics);
    const allMetrics = useAppSelector(selectMetrics);
    const likedMetrics = allMetrics
        .map(category => {
            const metrics = category.metrics;
            const lineage = getLineage(category, allMetrics);
            const url = lineage.map(c => c.slug).join('/');
            return metrics
                .map(metric => ({ ...metric, url: `/metrics/${url}/${metric.slug}` }));
        })
        .flat()
        .filter(metric => likedMetricIds[metric.id]);

    return likedMetrics.length > 0 ? (
        <ul className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:gap-8 xl:grid-cols-3">
            { likedMetrics.map(metric => (
                <li className="bg-gray-600 p-4 rounded-md" key={ metric.id }>
                    <Link to={metric.url} className="flex items-center justify-between">
                        <h2 className="font-bold text-gray-100">{ metric.name }</h2>
                        <div className="flex-shrink-0 ml-3">
                            <LikeButton metric={metric} />
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    ) : (
        <div className="w-full bg-gray-700 mx-auto max-w-2xl p-8 rounded-xl flex flex-col items-center justify-center text-center">
            <ChartBarIcon className="h-24 w-24 text-gray-400" />
            <h1 className="text-3xl text-gray-300 mt-8">Set a metric as favorite to see it in this list.</h1>
        </div>
    );
}

export default DefaultMetricPage;
