import { ChartBarIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import Dashboard from '../components/Dashboard';
import MetricsTree from '../components/metrics/MetricsTree';
import { MetricCategory } from '../models/metric';
import MetricsService from '../services/metrics';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setLikedMetrics } from '../store/slices/likedMetricsSlice';
import { selectMetrics, setMetrics } from '../store/slices/metricsSlice';

function MetricsPage() {
    const [visibleOnMobile, setVisibleOnMobile] = useState(false);
    const metrics = useAppSelector(selectMetrics);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const setLikedMetricsInStore = (categories: MetricCategory[]) => {
        const likes: { [key: number]: boolean } = {};
        for (const category of categories) {
            for (const metric of category.metrics) {
                likes[metric.id] = metric.has_liked;
            }
        }

        dispatch(setLikedMetrics({ likes }));
    }

    useEffect(() => {
        (new MetricsService()).list().then(res => {
            if (res?.success) {
                dispatch(setMetrics({ metrics: res.data.categories }));
                setLikedMetricsInStore(res.data.categories);
            }
        });
    }, []);

    useEffect(() => {
        setVisibleOnMobile(false);
    }, [location.pathname]);

    const toggleMenu = () => {
        const visible = !visibleOnMobile;
        if (visible) {
            document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
        } else {
            document.body.classList.remove('overflow-hidden', 'lg:overflow-auto');
        }

        setVisibleOnMobile(visible);
    }

    return (
        <Dashboard fullWidth={true}>
            <div className="flex h-screen">
                <button type="button" className="p-3 fixed top-0 right-0 z-50 text-white lg:hidden" onClick={ () => toggleMenu() }>
                    <ChartBarIcon className="h-6 w-6" />
                </button>
                { visibleOnMobile && <div className="fixed top-0 left-0 w-full h-full bg-black z-40 bg-opacity-25 lg:hidden" onClick={toggleMenu}></div> }
                <div className={[
                    'overflow-auto',
                    `${visibleOnMobile ? 'block fixed bottom-0 left-0 w-full z-50 h-3/4 shadow-xl' : 'hidden'}`,
                    'lg:relative lg:shadow-none lg:h-full lg:w-72 lg:flex-shrink-0 lg:block',
                ].join(' ')}>
                    <MetricsTree tree={ metrics } />
                </div>
                <div className="h-full overflow-auto w-full p-4 md:p-12">
                    <Outlet />
                </div>
            </div>
        </Dashboard>
    );
}

export default MetricsPage;
