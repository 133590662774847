import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { User } from '../../models/user'

// Define a type for the slice state
interface CurrentLikesState {
  likes: { [key: number]: boolean }
}

// Define the initial state using that type
const initialState: CurrentLikesState = {
    likes: {}
}

export const likedMetricsSlice = createSlice({
  name: 'likedMetrics',
  initialState,
  reducers: {
    setLikedMetrics: (state, action: PayloadAction<CurrentLikesState>) => {
      state.likes = action.payload.likes
    },
    addLikedMetric: (state, action: PayloadAction<{ id: number }>) => {
        state.likes = {
            ...state.likes,
            [action.payload.id]: true,
        }
    },
    removeLikedMetric: (state, action: PayloadAction<{ id: number }>) => {
        state.likes = {
            ...state.likes,
            [action.payload.id]: false,
        }
    },
  },
})

export const { addLikedMetric, removeLikedMetric, setLikedMetrics } = likedMetricsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectLikedMetrics = (state: RootState) => state.likedMetrics.likes

export default likedMetricsSlice.reducer
