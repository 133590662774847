import React, { useEffect, useState } from 'react';
import Dashboard from '../components/Dashboard';
import Skeleton from '../components/Skeleton';
import EmptyToolList from '../components/tools/EmptyToolList';
import ToolListItem from '../components/tools/ToolListItem';
import { Tool } from '../models/tool';
import ToolsService from '../services/tools';

function ToolsPage() {
    const [tools, setTools] = useState<Tool[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (new ToolsService()).list().then(res => {
            setLoading(false);
            if (res?.success) {
                setTools(res.data.tools);
            }
        });
    }, []);

    return (
        <Dashboard>
            { loading
                ? (
                    <>
                        <Skeleton className="h-12 w-48 mb-12" />
                        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                            <li><ToolListItem /></li>
                        </ul>
                    </>
                ) : <>
                    <h1 className="text-3xl font-bold text-white mb-12">Tools</h1>
                    { tools.length === 0 && <EmptyToolList /> }
                    { tools.length > 0 && <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                        {tools.map(tool => (
                            <li key={tool.id}>
                                <ToolListItem tool={ tool } />
                            </li>
                        ))}
                    </ul> }
                </>
            }
        </Dashboard>
    );
}

export default ToolsPage;
