import axios from "axios";
import environment from "../environment/environment";
import { LoginResponse } from "../models/auth";

class CsrfService {
    public async get(): Promise<any> {
        await axios.get<any>(environment.api.baseUrlWeb + '/sanctum/csrf-cookie');
        return true;
    }
}

export default CsrfService;
