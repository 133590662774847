import React from 'react';

type Props = {
    children: React.ReactNode;
    className?: string;
    type: 'button' | 'submit';
    onClick?: () => void;
}

function PrimaryButton(props: Props) {
    return (
        <button type={props.type} onClick={props.onClick} className={`flex justify-center items-center bg-primary-500 p-4 rounded-md w-full text-white font-medium ${props.className}`}>
            { props.children }
        </button>
    );
}

export default PrimaryButton;
