import React from 'react';
import LogoColor from './brand/LogoColor';

type Props = {
    children: React.ReactNode;
}

function Auth(props: Props) {
    return (
        <div className="min-h-screen w-screen bg-gradient-to-t from-gray-800 to-gray-900 flex items-center justify-center p-12">
            <div className="w-full bg-black bg-opacity-20 p-8 rounded-lg max-w-md">
                <LogoColor className="h-8 w-auto block mx-auto mb-8" />
                { props.children }
            </div>
        </div>
    );
}

export default Auth;
