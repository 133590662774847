import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { Provider } from 'react-redux';
import {
  Routes,
  BrowserRouter,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import axios from 'axios';
import environment from './environment/environment';
import MetricsPage from './pages/MetricsPage';
import ProtectedRoute from './components/ProtectedRoute';
import ToolsPage from './pages/ToolsPage';
import WalletPage from './pages/WalletPage';
import ProfilePage from './pages/ProfilePage';
import MetricDetailPage from './pages/MetricDetailPage';
import DefaultMetricPage from './pages/DefaultMetricPage';

axios.defaults.baseURL = environment.api.baseUrl;
axios.defaults.headers.common['Accept'] = 'application/json';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="metrics" element={<ProtectedRoute><MetricsPage /></ProtectedRoute>}>
              <Route path="" element={<DefaultMetricPage />} />
              <Route path="*" element={<MetricDetailPage />} />
            </Route>
            <Route path="tools" element={<ProtectedRoute><ToolsPage /></ProtectedRoute>} />
            <Route path="wallet" element={<ProtectedRoute><WalletPage /></ProtectedRoute>} />
            <Route path="profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
