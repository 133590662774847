import React from 'react';
import logoWhite from '../../assets/logo-white.png';

type Props = {
    className: string;
    wrapperClassName?: string;
}

function LogoWhite({ className, wrapperClassName }: Props) {
    return (
        <div className={ wrapperClassName || '' }>
            <img className={ className } src={ logoWhite } alt="Metary" />
        </div>
    );
}

export default LogoWhite;
