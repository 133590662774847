import React from 'react';
import logoColor from '../../assets/logo-color.png';

type Props = {
    className: string;
    wrapperClassName?: string;
}

function LogoColor({ className, wrapperClassName }: Props) {
    return (
        <div className={ wrapperClassName || '' }>
            <img className={ className } src={ logoColor } alt="Metary" />
        </div>
    );
}

export default LogoColor;
