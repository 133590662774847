import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { MetricCategory } from '../../models/metric'

// Define a type for the slice state
interface MetricsState {
  metrics: MetricCategory[]
}

// Define the initial state using that type
const initialState: MetricsState = {
    metrics: []
}

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setMetrics: (state, action: PayloadAction<MetricsState>) => {
      state.metrics = action.payload.metrics
    },
  },
})

export const { setMetrics } = metricsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectMetrics = (state: RootState) => state.metrics.metrics

export default metricsSlice.reducer
