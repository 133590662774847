import React from 'react';

type Props = {
    children: React.ReactNode;
}

function BaseLayout(props: Props) {
  return (
    <main>
      { props.children }
    </main>
  );
}

export default BaseLayout;
