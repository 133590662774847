import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GetProfileResponse } from '../models/user';
import ProfileService from '../services/profile';
import { useAppDispatch } from '../store/hooks';
import { removeUser, setUser } from '../store/slices/userSlice';
import LogoColor from './brand/LogoColor';

type Props = {
    children: React.ReactNode;
}

function AutoLogin(props: Props) {
    const [completed, setCompleted] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setCompleted(true);
            return;
        }

        (new ProfileService()).get().then(res => {
            if (res?.success) {
                dispatch(setUser({
                    user: res.data.user,
                    token,
                }));
            } else {
                dispatch(removeUser());
            }

            setCompleted(true);
        });
    }, []);

    return (completed ? <>
        { props.children }
    </> : <></>);
}

export default AutoLogin;
