import axios from "axios";
import { GetProfileResponse, UpdateProfileResponse } from "../models/user";

class ProfileService {
    public async get(): Promise<GetProfileResponse|null> {
        const token = localStorage.getItem('token');
        return axios.get<GetProfileResponse>('/user/profile', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }

    public async update(name: string, currentPassword?: string, newPassword?: string): Promise<UpdateProfileResponse|null> {
        const token = localStorage.getItem('token');
        return axios.put<UpdateProfileResponse>('/user/profile', {
            name,
            current_password: currentPassword,
            new_password: newPassword,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }
}

export default ProfileService;
