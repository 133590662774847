import React, { useEffect, useState } from 'react';
import { redirect } from 'react-router';
import Auth from '../components/Auth';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/forms/Input';
import AuthService from '../services/auth';
import CsrfService from '../services/csrf';
import { useAppDispatch } from '../store/hooks';
import { setUser } from '../store/slices/userSlice';
import { Link, useNavigate } from "react-router-dom";
import { store } from '../store/store';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const checkIfUserIsLoggedIn = () => {
        if (store.getState().profile.user) {
            return navigate('/metrics');
        }
    };

    useEffect(() => {
        (new CsrfService()).get().then(res => {

        });

        checkIfUserIsLoggedIn();

        store.subscribe(() => {
            checkIfUserIsLoggedIn();
        });
    }, []);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        (new AuthService()).login(email, password)
            .then(res => {
                if (res.success) {
                    dispatch(setUser({
                        user: res.data.user,
                        token: res.data.token,
                    }));
                    return navigate('/metrics');
                } else if (res.message) {
                    alert(res.message);
                }
            })
            .catch(err => {
                alert(err.response.data?.message || 'Something went wrong. Please try again.')
            });
    };

    return (
        <Auth>
            <form onSubmit={e => handleSubmit(e)}>
                <Input type="email" required={true} placeholder="Email" onChange={ $event => setEmail($event.target.value) } />
                <Input type="password" required={true} placeholder="Password" onChange={ $event => setPassword($event.target.value) } className="mt-4" />

                <PrimaryButton type="submit" className='mt-4'>Login</PrimaryButton>
            </form>
            <Link to="/sign-up" className='block text-gray-200 text-center mt-4 text-sm hover:text-white'>Don't have an account? Sign up</Link>
        </Auth>
    );
}

export default LoginPage;
