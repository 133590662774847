import axios from "axios";
import { GetMetricDetailsResponse, GetMetricsResponse, LikeMetricResponse } from "../models/metric";

class MetricsService {
    public async list(): Promise<GetMetricsResponse|null> {
        const token = localStorage.getItem('token');
        return axios.get<GetMetricsResponse>('/metrics/tree', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }

    public async get(slug: string): Promise<GetMetricDetailsResponse|null> {
        const token = localStorage.getItem('token');
        return axios.get<GetMetricDetailsResponse>(`/metrics/${slug}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }

    public async like(metricId: number): Promise<LikeMetricResponse|null> {
        const token = localStorage.getItem('token');
        return axios.post<GetMetricDetailsResponse>(`/metrics/${metricId}/like`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }

    public async unlike(metricId: number): Promise<LikeMetricResponse|null> {
        const token = localStorage.getItem('token');
        return axios.delete<GetMetricDetailsResponse>(`/metrics/${metricId}/like`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }
}

export default MetricsService;
