import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PrimaryButton from '../components/buttons/PrimaryButton';
import SecondaryButton from '../components/buttons/SecondaryButton';
import Dashboard from '../components/Dashboard';
import Input from '../components/forms/Input';
import Label from '../components/forms/Label';
import ProfileService from '../services/profile';
import { useAppDispatch } from '../store/hooks';
import { removeUser, setUser } from '../store/slices/userSlice';
import { store } from '../store/store';

function ProfilePage() {
    const user = store.getState().profile.user!;
    const [newName, setNewName] = useState(user.name);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        (new ProfileService()).update(newName, currentPassword, newPassword)
            .then(res => {
                if (res?.success) {
                    dispatch(setUser({
                        user: res.data.user,
                    }))

                    setCurrentPassword('');
                    setNewPassword('');
                    alert('Profile updated successfully!');
                } else if (res?.message) {
                    alert(res.message)
                }
            });
    };

    const logOut = () => {
        dispatch(removeUser());
        navigate('/login');
    };

    return (
        <Dashboard>
            <div className="max-w-2xl mx-auto pt-12">
                <form onSubmit={e => handleSubmit(e)}>
                    <img src={ user.profile_picture_url } alt={ user.name } className="h-32 w-32 rounded-full mx-auto block mb-12" />
                    <Input type="text" initialValue={ newName } required={true} placeholder="Your name" onChange={ $event => setNewName($event.target.value) } />
                    <div className="mt-12 p-8 bg-white bg-opacity-5 rounded-xl text-gray-50">
                        <h2 className='text-xl font-bold mb-4'>Change your password</h2>
                        <p className='text-gray-300'>Fill these fields only if you want to change your password.</p>

                        <Label text="Current password" optional={!(currentPassword.length > 0 || newPassword.length > 0)} className='mt-8'>
                            <Input type="password" required={currentPassword.length > 0 || newPassword.length > 0} placeholder="Current password" onChange={ $event => setCurrentPassword($event.target.value) } />
                        </Label>
                        <Label text="New password" optional={!(currentPassword.length > 0 || newPassword.length > 0)} className='mt-4'>
                            <Input type="password" required={currentPassword.length > 0 || newPassword.length > 0} placeholder="New password" onChange={ $event => setNewPassword($event.target.value) } />
                        </Label>
                    </div>
                    <PrimaryButton type="submit" className='mt-12'>Save changes</PrimaryButton>
                </form>
                <SecondaryButton type="button" onClick={ () => logOut() } className='mt-12 bg-opacity-25 hover:bg-opacity-50 transition'>Log out</SecondaryButton>
            </div>
        </Dashboard>
    );
}

export default ProfilePage;
