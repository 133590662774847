import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import { User } from '../../models/user'

// Define a type for the slice state
interface CurrentUserState {
  token?: string
  user?: User
}

// Define the initial state using that type
const initialState: CurrentUserState = {
  token: undefined,
  user: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<CurrentUserState>) => {
      if (action.payload.token) {
        state.token = action.payload.token
        localStorage.setItem('token', action.payload.token || '')
      }

      state.user = action.payload.user
    },
    removeUser: (state) => {
        state.token = undefined
        state.user = undefined
        localStorage.removeItem('token')
    },
  },
})

export const { setUser, removeUser } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.profile.user
export const selectToken = (state: RootState) => state.profile.token

export default userSlice.reducer
