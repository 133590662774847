import { HeartIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';
import LikeButton from '../components/metrics/LikeButton';
import MetricCharts from '../components/metrics/MetricCharts';
import Skeleton from '../components/Skeleton';
import { MetricDetail } from '../models/metric';
import MetricsService from '../services/metrics';


function MetricDetailPage() {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [metric, setMetric] = useState<MetricDetail>();
    const { '*': slug } = useParams();

    useEffect(() => {
        setLoading(true);
        (new MetricsService()).get(slug!).then(res => {
            setLoading(false);
            if (res?.success) {
                setMetric(res.data.metric);
            }
        });
    }, [slug]);

    return (loading
        ? (
            <>
                <div className="bg-gray-700 p-8 shadow-xl rounded-xl w-full">
                    <header className="flex justify-between items-start mb-2">
                        <Skeleton className="h-10 w-full" />
                        <div className="flex-shrink-0 ml-8">
                            <Skeleton className="h-10 w-10" />
                        </div>
                    </header>
                    <div>
                        <ul className="flex space-x-4">
                            <li><Skeleton className="h-8 w-12" /></li>
                            <li><Skeleton className="h-8 w-12" /></li>
                        </ul>
                    </div>
                    <Skeleton className="aspect-video mt-8" />
                </div>

                <div className="bg-gray-700 p-8 shadow-xl rounded-xl mt-12 prose prose-invert w-full max-w-none">
                    <Skeleton className="w-full h-6" />
                    <Skeleton className="w-3/4 h-6 mt-2" />
                </div>
            </>
        )
        : (
            metric
                ? (
                    <>
                        <div className="bg-gray-700 p-8 shadow-xl rounded-xl w-full">
                            <header className="flex justify-between items-start mb-2">
                                <h1 className="text-2xl font-bold text-gray-100">{ metric.chart_title }</h1>
                                <div className="flex-shrink-0 ml-8">
                                    <LikeButton metric={metric} />
                                </div>
                            </header>
                            <MetricCharts metric={ metric } />
                        </div>

                        { metric.description ? <div className="bg-gray-700 p-8 shadow-xl rounded-xl mt-12 prose prose-invert w-full max-w-none" dangerouslySetInnerHTML={{__html: metric.description}}></div> : <></> }
                    </>
                )
                : <Navigate to="/metrics" />
        )
    );
}

export default MetricDetailPage;
