import React, { useState } from 'react';
import { MetricDetail, MetricListItem } from '../../models/metric';
import {
    HeartIcon,
} from '@heroicons/react/24/outline'
import MetricsService from '../../services/metrics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addLikedMetric, removeLikedMetric, selectLikedMetrics } from '../../store/slices/likedMetricsSlice';

type Props = {
    metric: MetricListItem;
    size?: string;
}


function LikeButton(props: Props) {
    const likedMetrics = useAppSelector(selectLikedMetrics);
    const dispatch = useAppDispatch();
    const hasLiked = likedMetrics[props.metric.id] || false

    const handleOnClick = (event: React.MouseEvent, action: 'like'|'unlike') => {
        event.stopPropagation();
        event.preventDefault();
        (new MetricsService())[action](props.metric.id)
            .then(res => {
                if (res?.data?.liked === true) {
                    dispatch(addLikedMetric({ id: props.metric.id }))
                } else if (res?.data?.liked === false) {
                    dispatch(removeLikedMetric({ id: props.metric.id }))
                }
            })
    }

    return (
        <button type="button" className="p-2 group" onClick={e => hasLiked ? handleOnClick(e, 'unlike') : handleOnClick(e, 'like') }>
            <HeartIcon className={`${props.size || 'h-6 w-6'} text-white ${hasLiked ? 'fill-white' : 'group-hover:fill-white'}`} />
        </button>
    );
}

export default LikeButton;
