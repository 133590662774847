import React from 'react';

type Props = {
    className?: string;
    type?: string;
    placeholder?: string;
    required?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    initialValue?: string;
}

function Input(props: Props) {
    return (
        <input
            type={props.type || 'text'}
            placeholder={props.placeholder}
            required={props.required}
            onChange={props.onChange} className={`bg-black bg-opacity-20 p-4 rounded-md w-full text-white ${props.className}`}
            defaultValue={props.initialValue}
        />
    );
}

export default Input;
