import React from 'react';
import { Outlet } from 'react-router';
import AutoLogin from './components/AutoLogin';
import BaseLayout from './layouts/BaseLayout';

function App() {
  return (
    <BaseLayout>
      <AutoLogin>
        <Outlet />
      </AutoLogin>
    </BaseLayout>
  );
}

export default App;
