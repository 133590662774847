import axios from "axios";
import { LoginResponse, RegisterResponse } from "../models/auth";

class AuthService {
    public async login(email: string, password: string): Promise<LoginResponse> {
        const response = await axios.post<LoginResponse>('/login', { email, password });
        return response.data;
    }

    public async signUp(name: string, email: string, password: string): Promise<RegisterResponse> {
        const response = await axios.post<RegisterResponse>('/register', { name, email, password });
        return response.data;
    }
}

export default AuthService;
