import React from 'react';
import { MetricCategory } from '../../models/metric';
import MetricTreeCategory from './MetricTreeCategory';

type Props = {
    tree: MetricCategory[];
}

function MetricsTree(props: Props) {
    const parents = props.tree.filter(category => category.metric_category_id === null);
    return (
        <aside className="w-full bg-gray-700 rounded-r-xl min-h-full">
            <ul>
                { parents.map(category => (
                    <MetricTreeCategory key={ category.id } category={ category } categories={ props.tree } />
                )) }
            </ul>
        </aside>
    );
}

export default MetricsTree;
