import axios from "axios";
import { GetToolsResponse } from "../models/tool";

class ToolsService {
    public async list(): Promise<GetToolsResponse|null> {
        const token = localStorage.getItem('token');
        return axios.get<GetToolsResponse>('/tools', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            return res.data;
        }).catch((err) => {
            return err.response?.data || null;
        });
    }
}

export default ToolsService;
