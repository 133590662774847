import React from 'react';

type Props = {
    className?: string;
    text: string;
    optional: boolean;
    children: React.ReactNode;
}

function Label(props: Props) {
    return (
        <label
            className={`block w-full text-white ${props.className}`}
        >
            <span className="font-bold uppercase text-xs block mb-2">
                { props.text }
                { props.optional && <span className="text-gray-400 normal-case font-normal inline-block ml-2"> (Optional)</span>}
            </span>
            {props.children}
        </label>
    );
}

export default Label;
