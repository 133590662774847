import { MoonIcon, WalletIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Dashboard from '../components/Dashboard';

function WalletPage() {

    return (
        <Dashboard fullWidth={true}>
            <div className="h-screen flex items-center justify-center">
                <div className="w-full bg-gray-700 mx-auto max-w-2xl p-8 rounded-xl flex flex-col items-center justify-center text-center">
                    <WalletIcon className="h-24 w-24 text-gray-400" />
                    <h1 className="text-3xl text-gray-300 mt-8">Coming soon</h1>
                </div>
            </div>
        </Dashboard>
    );
}

export default WalletPage;
